import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import FluentIcon from '../../components/fluenticons/FluentIcon';
import { fiClose, fiNavigation } from '../../components/fluenticons';

export default function Website({ className, children }) {
  return (
    <div className={`${className}`}>
      <div className="m-auto max-w-screen-xl px-5 md:px-20">
        <Header className={className} />
        <div>{children}</div>
        <Footer />
      </div>
    </div>
  );
}

function Header({ className }) {
  const router = useRouter();
  const path = router.pathname;

  const [navOpen, setNavOpen] = useState(false);

  const cRef = useRef();

  const closeNav = () => {
    setNavOpen(false);
  };

  const openNav = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTimeout(() => {
      setNavOpen(true);
    }, 100);
  };

  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        setNavOpen(false);
      }
    };

    const clickFunction = (e) => {
      setNavOpen(false);
    };

    document.addEventListener('keydown', escFunction, false);
    document.addEventListener('click', clickFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('click', clickFunction, false);
    };
  }, []);

  const login = () => {
    router.push('/login');
  };

  return (
    <header className="pb-12">
      <div className="flex flex-row text-xl py-12 hidden md:flex">
        <Link href="/">
          <a href="/">
            <div className="cursor-pointer text-brand-base font-black">
              INFLOSO
            </div>
          </a>
        </Link>
        <div className="flex-1"></div>
        <HLink href="/influencer" selected={path === '/influencer'}>
          Influencer
        </HLink>
        <div className="flex-1"></div>
        <HLink href="/brand" selected={path === '/brand'}>
          Brand
        </HLink>
        <div className="flex-1"></div>
        <HLink href="/agency" selected={path === '/agency'}>
          Agency
        </HLink>
        <div className="flex-1"></div>
        <HLink href="/login" selected={path === '/login'}>
          Login
        </HLink>
        <div className="flex-1"></div>
        <HLink href="/register" selected={path === '/register'}>
          Sign Up
        </HLink>
      </div>
      <div className="flex flex-row gap-3 items-center text-xl pt-5 md:hidden ">
        <div className="cursor-pointer -mt-1">
          {navOpen ? (
            <div onClick={closeNav}>
              <FluentIcon icon={fiClose} />
            </div>
          ) : (
            <div onClick={openNav} ref={cRef}>
              <FluentIcon icon={fiNavigation} />
            </div>
          )}
        </div>
        <Link href="/">
          <a href="/">
            <div className="cursor-pointer text-brand-base font-black">
              INFLOSO
            </div>
          </a>
        </Link>
        <div className="flex-1"></div>
        <div className="text-center">
          <div
            className="bg-brand-base hover:bg-brand-blue text-white font-bold inline-block py-1 px-5 cursor-pointer rounded-20px text-lg"
            onClick={login}
          >
            Login
          </div>
        </div>
      </div>
      {navOpen && (
        <div
          className={`customDropShadowNoHover absolute border-1 border-white h-sscreen z-10 flex flex-col gap-5 px-10 pt-3 pb-10 text-xl w-sfull md:hidden ${
            className || 'bg-white'
          }`}
        >
          <HLink href="/influencer" selected={path === '/influencer'}>
            Influencer
          </HLink>
          <HLink href="/brand" selected={path === '/brand'}>
            Brand
          </HLink>
          <HLink href="/agency" selected={path === '/agency'}>
            Agency
          </HLink>
          <HLink href="/login" selected={path === '/login'}>
            Login
          </HLink>
          <HLink href="/register" selected={path === '/register'}>
            Sign Up
          </HLink>
        </div>
      )}
      <div className="flex gap-2 hidden">
        <div className="hidden sm:inline-block">sm</div>
        <div className="hidden md:inline-block">md</div>
        <div className="hidden lg:inline-block">lg</div>
        <div className="hidden xl:inline-block">xl</div>
      </div>
    </header>
  );
}

function HLink({ href, children, selected = false }) {
  const border = href === '/influencer' ? 'white' : 'black';
  const text = href === '/influencer' ? 'white' : 'black';
  return (
    <Link href={href}>
      <div
        className={`cursor-pointer font-bold ${
          selected
            ? `border-l-4 border-brand-base pl-3 text-brand-base md:border-l-0 md:pl-0 md:text-${text} md:border-b-1 md:border-${border} `
            : ''
        }`}
      >
        <a href={href}>{children}</a>
      </div>
    </Link>
  );
}

export function Footer() {
  return (
    <div className="mt-10 pb-10 md:pb-28">
      <div className="flex flex-col md:flex-row text-center md:text-left font-semibold text-lg">
        <div className="md:pr-20">
          <Link href="/">
            <a href="/">
              <img
                src="/favicon-transparent-l-200x200.png"
                className="m-auto"
              />
              <div className="-mt-16 text-center">INFLOSO</div>
            </a>
          </Link>
          <a
            href="https://apps.apple.com/in/app/infloso/id1659660559"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/badge/appstore_badge_en.svg"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.infloso"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/badge/googleplay_badge_en.svg"
              style={{ height: '60px', margin: 'auto' }}
            />
          </a>
        </div>
        <div>
          <FLink href="/about">About</FLink>
          <FLink href="/contact">Contact</FLink>
          <FLink href="/blogs">Blogs</FLink>
          <FLink href="/about#open_positions">Career</FLink>
          <FLink href="/explore">Explore</FLink>
          <FLink href="/how-it-works">How It Works</FLink>
          <FLink href="/trust">Trust & Safety</FLink>
        </div>
        <div className="flex-1"></div>
        <div>
          <FLink href="/influencer">Influencer</FLink>
          <FLink href="/brand">Brand</FLink>
          <FLink href="/agency">Agency</FLink>
          <FLink href="/privacy-policy">Privacy Policy</FLink>
          <FLink href="/terms-of-service">Terms</FLink>
          <FLink href="/cookie-policy">Cookies</FLink>
          <FLink href="/links">Links</FLink>
        </div>
        <div className="flex-1"></div>
        <div>
          <FLink href="/campaign-standards">Campaign Standards</FLink>
          <FLink href="/review">Campaign Reviewal</FLink>
          <FLink href="/community-standards">Community Standards</FLink>
          <FLink href="/help">Help & FAQ</FLink>
          <FLink href="/sitemap">Sitemap</FLink>
        </div>

        <div className="flex-1"></div>
        <div>
          <FLink href="/brand#brand_awareness">Brand Awareness</FLink>
          <FLink href="/brand#product_launch">Product Launch</FLink>
          <FLink href="/brand#events">Events</FLink>
          <FLink href="/brand#app_downloads">App Downloads</FLink>
          <FLink href="/brand#website_visits">Website visits</FLink>
          <FLink href="/brand#reviews">Reviews</FLink>
          <FLink href="/brand#drive_sales">Drive Sales</FLink>
        </div>
      </div>
      <div className="flex flex-row gap-10 mt-10">
        <div className="flex-1"></div>
        <a
          href="https://twitter.com/inflosoindia"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/icons/bw/twitter.png" />
        </a>
        <a
          href="https://www.facebook.com/infloso/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/icons/bw/facebook.png" />
        </a>
        <a
          href="https://www.instagram.com/infloso/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/icons/bw/instagram.png" />
        </a>
        <a
          href="https://www.linkedin.com/company/infloso/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/icons/bw/linkedin.png" />
        </a>
        {/*<a href="" target="_blank" rel="noreferrer">*/}
        {/*  <img src="/icons/bw/youtube.png" />*/}
        {/*</a>*/}

        <div className="flex-1"></div>
      </div>
    </div>
  );
}

function FLink({ href, children }) {
  return (
    <div className="mt-4 cursor-pointer">
      <Link href={href}>
        <div>
          <a href={href}>{children}</a>
        </div>
      </Link>
    </div>
  );
}
