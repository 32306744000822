import React from 'react';
import Link from 'next/link';
import { shallowEqual, useSelector } from 'react-redux';
import Website, { Footer } from '../layouts/website';
import Button from '../components/action/Button';
import Head from 'next/head';

export default function Home() {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );

  // const isClient = typeof location !== 'undefined';
  // const router = useRouter();
  //
  // if (isClient && user && user.name) {
  //   router.push('/dashboard');
  //   return <FullPageLoading />;
  // }

  return (
    <Website>
      <Head>
        <title>Infloso</title>
        <meta
          name="description"
          content="Infloso uses AI to help brands go viral, through social media users & influencers, completely automated, at scale, on any platform, starting with just INR 100."
        />
        <link rel="icon" href="/favicon-rounded-200x200.png" />
      </Head>
      <div className="flex flex-row md:gap-20 md:-mt-20">
        <div className="hiddwen md:block">
          <img src="/images/website/home_orange.png" />
          <img
            src="/images/website/home_purple.png"
            className="mt-5 md:mt-20"
          />
        </div>
        <div>
          <img src="/images/website/home_hero.png" className="" />
        </div>
        <div className="hidwden md:block">
          <img src="/images/website/home_red.png" />
          <img src="/images/website/home_yellow.png" className="mt-5 md:mt-0" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-10 my-10">
        <div>
          <Link href="/influencer">
            <a href="/influencer">
              <div className="bg-brand-base text-center w-full hover:bg-brand-blue text-white font-bold inline-block py-2 px-8 cursor-pointer rounded-20px text-xl">
                Influencer
              </div>
            </a>
          </Link>
          <div className="text-xl mt-5 text-center hidden md:block">
            A World Where You Get
            <br />
            Paid To Create Content.
          </div>
        </div>
        <div className="flex-1 hidden md:block"></div>
        <div>
          <Link href="/brand">
            <a href="/brand">
              <div className="bg-brand-base text-center w-full hover:bg-brand-blue text-white font-bold inline-block py-2 px-8 cursor-pointer rounded-20px text-xl">
                Brand
              </div>
            </a>
          </Link>
          <div className="text-xl mt-5 text-center hidden md:block">
            Make Your Business
            <br />
            Go Viral.
          </div>
        </div>
        <div className="flex-1 hidden md:block"></div>
        <div>
          <Link href="/agency">
            <a href="/agency">
              <div className="bg-brand-base text-center w-full hover:bg-brand-blue text-white font-bold inline-block py-2 px-8 cursor-pointer rounded-20px text-xl">
                Agency
              </div>
            </a>
          </Link>
          <div className="text-xl mt-5 text-center hidden md:block">
            More Than A Platform
            <br />
            For Your Agency.
          </div>
        </div>
      </div>
      <hr />
    </Website>
  );
}
